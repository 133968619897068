<template>
  <div
    class="union-order-list"
    v-loading="addOrderLoading"
    :element-loading-text="'供应商当前正在执行【导入并发货】...' + progress"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.4)"
  >
    <en-table-layout
      :tableData="pageData.data"
      :loading="loading"
      :span-method="orderSpanMethod"
      @selection-change="orderListSelectionChange"
    >
      <template slot="toolbar">
        <el-form-item label="订单状态" class="col-auto">
          <el-select style="width: 100px;" v-model="params.order_status" size="medium">
            <el-option
              v-for="item in MixinOrderStatusOptions[isJDSupplier ? 2 : 1]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="下单日期" class="col-auto">
          <el-date-picker
            style="width: 230px"
            v-model="advancedForm.order_time_range"
            type="daterange"
            size="medium"
            :editable="false"
            unlink-panels
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="关键词" class="col-auto">
          <el-input v-model="keywords" size="medium" placeholder="请输入关键词" clearable>
            <el-select
              slot="prepend"
              v-model.trim="keywordsType"
              size="medium"
              placeholder="关键词类型"
              style="width: 130px"
            >
              <el-option label="订单编号" value="order_sn"></el-option>
              <el-option label="买家账号" value="buyer_name"></el-option>
              <el-option label="商品名称" value="goods_name"></el-option>
              <el-option label="物流单号" value="ship_no"></el-option>
              <el-option label="收货人手机号" value="ship_mobile"></el-option>
              <el-option v-if="is_JdSupplier" label="所属企业" value="enterprise_name"></el-option>
              <el-option v-if="is_JdSupplier" label="根据京东单号" value="ext_order_id"></el-option>
            </el-select>
          </el-input>
        </el-form-item>

        <el-button @click="advancedSearchEvent" size="small" type="primary" class="ml-2" style="margin-right: 10px">搜索</el-button>

        <div class="col"></div>
        <div class="union-style-1587">
          <el-button
            @click="submitImport(1)"
            :loading="importLoading1"
            size="small"
            type="primary"
          >导出全部</el-button>
          <el-button
            @click="submitImport(0)"
            :loading="importLoading"
            size="small"
            type="primary"
          >导出选中</el-button>

          <el-button
            v-if="shopInfo.shop_type !== 1 && !isJDSupplier"
            @click="batchImport = true; funcName = 'importAndShipSellerOrder';"
            size="small"
            type="primary"
          >导入并发货
          </el-button>
          <el-button
            v-if="!isJDSupplier"
            :loading="importLoading2"
            @click="cancelPicking"
            size="small"
            type="primary"
          >取消拣货</el-button>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column type="selection" />

        <el-table-column label="订单编号" prop="sn" width="140" fixed="left"/>
        <el-table-column v-if="is_JdSupplier" label="京东单号" prop="ext_order_id" width="140" fixed="left"/>

        <el-table-column label="订单总额" width="100" fixed="left">
          <template slot-scope="{row}">{{ row.order_total_price | unitPrice('￥') }}</template>
        </el-table-column>

        <el-table-column
          label="图片"
          width="60"
          class-name="goods-cover-wrapper"
          fixed="left"
        >
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.goods_image" style="width: 300px;" alt/>
              <img slot="reference" :src="row.goods_image" class="goods-cover" alt/>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="goods_name" label="商品名称" width="200" show-overflow-tooltip fixed="left">
            <template slot-scope="{row}">
              {{ row.goods_name.replaceAll('amp;','').replaceAll(' ','&nbsp;') }}
            </template>
          </el-table-column>

        <el-table-column label="商品单价" width="100">
          <template slot-scope="{row}">
            <template v-if="shopInfo.shop_type !== 2">
              <template v-if="row.goods_type === 'NORMAL'">
                <span>{{ row.shop_purchase_price | unitPrice('￥') }}</span>
              </template>
              <template v-else>
                {{ row.revise_exchange_money | unitPrice('￥') }} +
                {{ row.revise_exchange_point }}积分
              </template>
            </template>
            <template v-else>
              <template v-if="row.goods_type === 'NORMAL'">
                <span>{{ row.jiage | unitPrice('￥') }}</span>
              </template>
              <template v-else>
                {{ row.exchange_money | unitPrice('￥') }} +
                {{ row.exchange_point }}积分
              </template>
            </template>
          </template>
        </el-table-column>

        <el-table-column label="商品数量" prop="goods_num" width="80"/>

        <el-table-column label="售后状态" width="100">
          <template slot-scope="{row}">
            {{ MixinGetOrderStatus(row.service_status) }}
            <div v-if="row.service_status === 'COMPLETED' && row.state === 0" style="color: red;">
              (选择新商品)
            </div>
          </template>
        </el-table-column>

        <el-table-column label="物流状态" width="100">
          <template slot-scope="{row}">
            <span v-if="row.logistics_status_text">{{row.logistics_status_text}}</span>
            <span v-else>{{ row.state === 0 ? '未发货' : (row.state === 2 ? '已发货' : '已收货') }}</span>       
          </template>
        </el-table-column>

        <el-table-column label="物流单号" width="300" show-overflow-tooltip>
          <template slot-scope="{row}">
            <template
              v-if="row.delivery"
            >{{ row.delivery.logiName }}: {{ row.delivery.deliveryNo }}
            </template>
          </template>
        </el-table-column>

        <el-table-column label="订单状态" width="80">
          <template slot-scope="{row}">
            {{ row.order_status_text === '待发货' && row.logistics_status === 1 && !is_JdSupplier? '拣货中' : row.order_status_text }}
            <span style="color: red;">
                            {{
                row.hangup_status === 1 ? '(店铺挂起)' :
                  (row.hangup_status === 2 ? '(企业挂起)' :
                    (row.hangup_status === 3 ? '(供应商挂起)' : ''))
              }}
                        </span>
          </template>
        </el-table-column>

        <el-table-column label="下单时间" width="150">
          <template slot-scope="{row}">{{ row.create_time | unixToDate }}</template>
        </el-table-column>

        <el-table-column
          label="用户账号"
          prop="member_name"
          width="150"
          show-overflow-tooltip
          class-name="font-family-tttgb"
        />

        <el-table-column label="用户备注信息" width="160">
          <template slot-scope="{row}">
            <el-tooltip class="item" effect="dark" :content="row.remark" placement="top">
              <div class="union-ordert-list remark-box">
                {{row.remark}}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column label="所属企业" prop="enterprise_name" show-overflow-tooltip width="120" v-if="is_JdSupplier"/>
        <el-table-column label="备注信息" align="center" width="120">
          <template v-if="row.remark_update_date&&row.order_remark" slot-scope="{row}">
            <el-tooltip placement="top">
              <div slot="content">
              {{row.remark_update_date| unixToDate }}
              <br />
              {{row.order_remark.replaceAll("/n", "\n")}}
              </div>
              <div class="conceal">
              {{row.remark_update_date| unixToDate }}
              <br />
              {{row.order_remark.replaceAll("/n", "\n")}}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作" :width="isJDSupplier ? 170 : 290" fixed="right">
          <template slot-scope="{row}">
            <el-button
              type="primary"
              size="small"
              @click="handleOperateOrder(row.sn)"
            >查看详情
            </el-button>
            <el-button
              v-if="row.order_status === 'SHIPPED' && !isJDSupplier"
              type="primary"
              size="small"
              @click="openLogisticsDialog(row.sn)"
            >修改物流信息</el-button>
            <el-button
              type="primary"
              size="small"
              v-if="!isJDSupplier && row.ship_button"
              @click="deliverDialog(row.sn, ship_no)"
            >发货
            </el-button>
            <el-button type="primary" size="small" @click="openRemarkDialog(row)">备注</el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no"
        :page-size="pageData.page_size"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :total="pageData.data_total"
      ></el-pagination>
    </en-table-layout>
    <x-dialog :proxy="logisticsDialog">
      <en-table-layout :table-data="sku_list">
        <template slot="table-columns">
          <el-table-column prop="goods_sn" label="商品编号" width="150"></el-table-column>
          <el-table-column prop="goods_name" label="商品名称" min-width="200">
            <template slot-scope="{row}">
              {{ row.goods_name.replaceAll('amp;','').replaceAll(' ','&nbsp;') }}
            </template>
          </el-table-column>
          <el-table-column prop="ship_num" label="商品数量" width="100"></el-table-column>
          <el-table-column label="物流公司" width="200">
            <template slot-scope="scope">
              <el-select
                size="mini"
                v-model="scope.row.logi_id"
                placeholder="请选择"
                @change="filterLogisticsData(scope.row)"
              >
                <el-option
                  v-for="item in logisticsData"
                  :key="item.logi_id"
                  :label="item.name"
                  :value="item.logi_id"
                />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="物流单号" width="260px">
            <template slot-scope="scope">
              <ul class="wlcz" style="list-style: none; padding-left: 10px; margin-bottom: 0;">
                <el-input
                  size="mini"
                  placeholder="请输入物流单号"
                  v-model="scope.row.delivery_no"
                ></el-input>
              </ul>
            </template>
          </el-table-column>
        </template>
      </en-table-layout>
    </x-dialog>
    <!-- 发货 -->
    <el-dialog
      title="订单发货"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <div v-if="$store.getters.shopInfo.shop_type !== 1" class="fhBox">
        <div class="row no-gutters">
          <div class="col"></div>
          <el-button
            type="primary"
            size="small"
            :disabled="!selectList.length || orderDetail.pay_status !== 'PAY_YES'"
            @click="submitFhing"
          >确认发货
          </el-button>
          <!-- <el-button
  v-if="
      orderDetail.ship_status === 'SHIP_NO' &&
        orderDetail.pay_status === 'PAY_YES'
    "
  type="primary"
  @click="confirmStock"
          >确认备货</el-button>-->
          <!-- <el-button type="primary" @click="addLogisticsInfo">添加物流信息</el-button> -->
        </div>
        <el-table
          border
          ref="multipleTable"
          :data="sku_list"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: 'rgba(230, 236, 247, 1)',
          }"
        >
          <el-table-column :selectable="checkboxT" type="selection" width="45"></el-table-column>
          <el-table-column prop="sku_sn" label="商品编号" width="180px"></el-table-column>
          <el-table-column prop="name" label="商品名称以及规格" min-width="120px"></el-table-column>
          <el-table-column prop="num" label="商品数量" width="80px"></el-table-column>
          <el-table-column label="物流公司" width="180px">
            <template slot-scope="scope">
              <el-select
                size="mini"
                :disabled="scope.row.state === 3"
                v-model="scope.row.logi_id"
                placeholder="请选择"
                @change="filterLogisticsData(scope.row)"
              >
                <el-option
                  v-for="item in logisticsData"
                  :key="item.logi_id"
                  :label="item.name"
                  :value="item.logi_id"
                ></el-option>
              </el-select>
              <input type="text" style="display: none" v-model="scope.row.logi_name"/>
            </template>
          </el-table-column>

          <el-table-column label="物流操作" width="200px">
            <template slot-scope="scope">
              <ul class="wlcz">
                <li v-for="(item, index) in scope.row.delivery_list" :key="index" style="list-style: none;">
                  <!-- <el-input-number
  :disabled="scope.row.state == 3"
  :controls="false"
  size="mini"
  :min="1"
  @change="numChange(scope.$index)"
  v-model="item.ship_num"
  :class="{ null: !item.ship_num && item.ship_num != 0 }"
                  ></el-input-number>-->
                  <el-input
                    :disabled="scope.row.state === 3"
                    size="mini"
                    placeholder="请输入物流单号"
                    v-model="item.delivery_no"
                  ></el-input>
                  <!-- <template v-if="scope.row.state != 3">
  <i
    v-if="!index"
    @click="tianjia(scope.$index)"
    class="el-icon-plus"
  ></i>
  <i
    v-else
    @click="tianjia(scope.$index, index)"
    class="el-icon-minus"
  ></i>
                  </template>-->
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <x-dialog :proxy="remarkDialog">
      <div style="padding:10px">
        <el-input
          type="textarea"
          :autosize="{ minRows: 8,maxRows: 10}"
          placeholder="请输入不超100字的备注信息"
          maxlength="100"
          v-model="remarkForm.remark"
        ></el-input>
      </div>
    </x-dialog>

    <el-dialog
      :title="funcName === 'importAndShipSellerOrder' ? '批量导入并发货' : '导入历史物流信息'"
      :visible.sync="batchImport"
      width="85%"
      class="import-order-dialog"
      @closed="importData = []"
    >
      <div class="buttonAll">
        <upload-excel-component :on-success="excelSuccess" :cancel-loading = 'true'/>
      </div>
      <el-table
        :data="importData"
        :header-cell-style="{
          textAlign: 'center',
          backgroundColor: 'rgba(230, 236, 247, 1)',
        }"
      >
        <el-table-column label="序号" align="center">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in templateKey"
          :prop="item"
          :key="item"
          align="center"
          :label="templateHedaer[index]"
        ></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="batchImport = false">取 消</el-button>
        <el-button type="primary" @click="submitFh">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as API_order from "@/api/order";
import * as API_Member from "@/api/member";
import * as API_logistics from "@/api/expressCompany";
import {mapGetters} from "vuex";
import {handleDownload, mergeSkuList} from "@/utils";
import {CategoryPicker} from "@/components";
import UploadExcelComponent from "@/components/UploadExcel";
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import XDialog from "@/components/x-dialog/x-dialog";
import {$xDialog} from "@/components/x-dialog/dialog.proxy";

export default {
  name: "orderList",
  components: {
    EnTableLayout,
    CategoryPicker,
    UploadExcelComponent,
    XDialog
  },
  computed: {
    ...mapGetters(["shopInfo"]),
    timeDefault() {
      const date = new Date();
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() - 1)
      );
    }
  },
  data() {
    return {
      /** 列表loading状态 */
      loading: false,
      importLoading: false,
      importLoading1: false,
      importLoading2: false,
      dialogVisible: false,
      remarkDialog: $xDialog.create({
        title: "备注信息",
        width: "40vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.editRemark
      }),
      logisticsDialog: $xDialog.create({
        title: "物流信息",
        width: "80vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.editLogistics
      }),
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        self_goods: 0,
        order_status: "ALL",
        ...this.$route.query
      },
      // 备注信息
      remarkForm: {
        sn: "",
        platform: "",
        remark: "",
        member_name: ""
      },
      /** 物流信息 */
      logisticsData: [],
      formInline: {
        logi_id: ""
      },

      /** 列表分页数据 */
      pageData: {data: []},
      keywordsType: "order_sn",
      keywords: "",
      obj: {},
      shop_name: "",
      shop_num: "",
      tableComData1: [],
      tableComData2: [],
      comParams: {
        cgrade: 2,
        page_no: 1,
        page_size: 20
      },
      /*  快递公司ID和名称 */
      selectValue: "",

      /*  订单编号 */
      sn: "",

      /* 物流单号 */
      ship_no: "",

      /** 高级搜索数据 */
      advancedForm: {
        shop_member_unit_id: "",
        shop_member_unit_id_two: "",
        order_time_range: null
      },
      // 列表选中数据
      tempList: [],
      importData: [],
      isError: false,
      templateHedaer: [
        "商品名称以及规格",
        "产品ID",
        "数量",
        "详细地址",
        "姓名",
        "电话",
        "订单号",
        "订单状态",
        "物流公司",
        //"物流状态",
        "物流单号",
        "买家账号"
      ],
      templateKey: [
        "name",
        "product_id",
        "num",
        "ship_addr",
        "ship_name",
        "ship_mobile",
        "sn",
        "order_status_text",
        "logi_name",
        //"ship_status_text",
        "ship_no",
        "member_name"
      ],
      funcName: "",
      /** 表格最大高度 */
      tableMaxHeight: document.body.clientHeight - 54 - 34 - 50 - 15,
      batchImport: false,
      /*订单商品数据*/
      sku_list: [],
      selectList: [],
      /** 订单详情数据 */
      orderDetail: {},

      userInfo: this.$store.state.user.user,
      // 发货数量
      ship_num: 0,
      is_JdSupplier: this.$store.getters.shopInfo.shop_id === 66,
      addOrderLoading: false,
      pollTime: null,
      progress: '0%'
    };
  },
  mounted() {
    this.pollImportResult('init')
    delete this.params.market_enable;
    this.params = {
      ...this.params,
      ...this.$route.query
    };

    const {type} = this.$route.query;

    switch (type) {
      case "1":
        this.params.order_status = "WAIT_SHIP";
        break;
      case "2":
        this.params.order_status = "ALL";
        var arr = [];
        arr.push(this.timeDefault);
        arr.push(this.timeDefault);
        // 初始化搜索，默认为前一天
        this.advancedForm.order_time_range = arr;
        var timestr = this.getTargetTime(this.timeDefault);
        this.params = {
          ...this.params,
          start_time: timestr,
          end_time: timestr
        };
        break;
      default:
        break;
    }

    this.GET_OrderList();
    // 获取物流公司信息
    this.getLogisticsCompanies();
    this.GET_UnitList();
  },
  beforeRouteUpdate(to, from, next) {
    delete this.params.market_enable;
    this.params = {
      ...this.params,
      ...this.$route.query
    };
    this.GET_OrderList();
    next();
  },
  methods: {
    // 取消拣货
    cancelPicking() {
      if (this.tempList.length === 0)
        return this.$message.error("请先勾选要取消拣货的订单");
      this.$confirm('确定要将当前勾选的订单取消拣货吗？', '提示', { type: 'warning' }).then(() => {
        this.importLoading2 = true
        let order_sn_s = this.tempList.map(item => item.sn ).join(',')
        API_order.cancelPicking({order_sn_s}).then(res => {
          this.GET_OrderList();
          this.importLoading2 = false
          return this.$message.success(res);
        })
			})
    },
    orderListSelectionChange(list) {
      this.tempList = list;
    },
    orderSpanMethod({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      if (this.is_JdSupplier) {
        if (columnIndex >= 4 && columnIndex <= 9) {
          return {
            rowspan: 1,
            colspan: 1
          };
        } else {
          return row.span;
        }
      } else {
        if (columnIndex >= 3 && columnIndex <= 9) {
          return {
            rowspan: 1,
            colspan: 1
          };
        } else {
          return row.span;
        }
      }
    },

    /** 确认备货 */
    confirmStock() {
      this.$confirm("确认执行此操作?", "提示", {type: "warning"}).then(
        () => {
          API_order.confirmStock([this.sn], {
            order_sns: [this.sn]
          }).then(() => {
            this.$message.success("备货成功！");
            this.GET_OrderList();
            this.dialogVisible = false;
          });
        }
      );
    },

    numChange(index) {
      let num = 0;
      if (this.sku_list[index].delivery_list) {
        this.sku_list[index].delivery_list.forEach((item, index) => {
          if (item.num >= 1) {
            num += item.num;
          }
        });
        if (num > this.sku_list[index].num) {
          this.$message.error("物流商品数量超过订单商品数量，请检查");
        }
      }
    },

    tianjia($index, index) {
      if (index >= 0) {
        this.sku_list[$index].delivery_list.splice(index, 1);
        this.sku_list = [...this.sku_list];
      } else {
        this.sku_list[$index].delivery_list =
          this.sku_list[$index].delivery_list || [];
        this.sku_list[$index].delivery_list.push({});
        this.sku_list = [...this.sku_list];
      }
    },

    filterLogisticsData(row) {
      this.logisticsData.forEach(res => {
        if (res.logi_id === row.logi_id) {
          row.logi_name = res.name;
        }
      });
    },
    handleSelectionChange(list) {
      this.selectList = list;
    },
    checkboxT(row, index) {
      return row.state !== 3;
    },
    getTargetTime(t) {
      var d = t.split(" ")[0],
        date = new Date();
      date.setYear(d.split("-")[0]);
      date.setMonth(d.split("-")[1] - 1);
      date.setDate(d.split("-")[2]);
      return date.getTime();
    },
    handleClose() {
      this.dialogVisible = false;
    },
    resetSelfGoods() {
      const route = this.$route.path.split("/")[2];
      if (route === "order-listb") {
        this.params.self_goods = 1;
      }
    },
    /** 获取物流公司信息列表 */
    getLogisticsCompanies() {
      API_logistics.getExpressCompanyList({}).then(res => {
        this.logisticsData = res;
      });
    },
    /** 点击发货 */
    deliverDialog(sn, ship_no) {
      this.dialogVisible = true;
      this.sn = sn;
      this.ship_no = ship_no;
      this.sku_list = [];
      API_order.getOrderItemsList(sn).then(response => {
        if (response && response.length) {
          let sku_list = [...response];
          sku_list.forEach(item => {
            if (!item.delivery_list || !item.delivery_list.length || !item.delivery_list[0].delivery_no) {
              item.delivery_list = [{}];
            }
            if (!item.state && item.service_status === "NOT_APPLY") {
              this.sku_list.push(item)
            }
          });
          this.rawData = JSON.stringify(this.sku_list)
        }
      });
      if (this.shopInfo.shop_type === 2) {
        API_order.getSellerOrderDetail(sn).then(response => {
          // 订单信息
          if (
            response.order_status === "CANCELLED" ||
            response.service_status === "APPLY"
          ) {
            this.$message.error("该订单已取消，不需要发货");
          }
          this.orderDetail = response;
        });
      } else {
        API_order.getOrderDetail(sn).then(response => {
          // 订单信息
          if (
            response.order_status === "CANCELLED" ||
            response.service_status === "APPLY"
          ) {
            this.$message.error("该订单已取消，不需要发货");
          }
          this.orderDetail = response;
        });
      }
    },
        /** 确认发货 */
    submitFhing() {
      for (let i = 0; i < this.selectList.length; i++) {
        let index = i;
        let item = this.selectList[i];
        let ship_num = 0;
        if (!item.logi_id) {
          this.$message.error("请选择物流公司");
          return false;
        }
        if (item.delivery_list) {
          for (let j = 0; j < item.delivery_list.length; j++) {
            let item1 = item.delivery_list[j];
            if (!item1.delivery_no) {
              this.$message.error("请输入物流单号");
              return false;
            }
            if (item1.ship_num >= 1) {
              ship_num += item1.ship_num;
            }
          }
          if (ship_num > this.selectList[index].num) {
            this.$message.error(
              "物流商品数量超过订单商品数量，请检查"
            );
            return false;
          }
        }
      }
      this.$confirm("确认发货?", "提示", {type: "warning"}).then(() => {
        const fhInfo = {
          order_sn: this.sn,
          delivery_dolist: []
        };
        let sku_list = [];
        JSON.parse(this.rawData).forEach(elem=>{
          this.selectList.forEach((item, index) => {
              item.delivery_list.forEach((item1, index1) => {
                if(JSON.stringify(elem.delivery_list)==="[{}]"){
                  if(elem.goods_id === item.goods_id){
                    fhInfo.delivery_dolist.push({
                    logi_id: item.logi_id,
                    logi_name: item.logi_name,
                    order_sn: this.sn,
                    sku_id: item.product_id,
                    goods_id: item.goods_id,
                    goods_name: item.name,
                    goods_sn: item.sku_sn,
                    ship_num: item.num,
                    delivery_no: item1.delivery_no
                    });
                  }
                }else{
                  sku_list.push({
                  logi_id: item.logi_id,
                  logi_name: item.logi_name,
                  order_sn: this.sn,
                  sku_id: item.product_id,
                  goods_id: item.goods_id,
                  goods_name: item.name,
                  goods_sn: item.sku_sn,
                  id:item1.id,
                  ship_num: item.num,
                  delivery_no: item1.delivery_no
                  })
                }
              });
          });
        })
        if(sku_list.length !== 0&&fhInfo.delivery_dolist.length !== 0){
          API_order.deliveryNew(fhInfo).then(() => {
            API_order.editLogistics(sku_list).then(res => {
            this.$message.success("保存成功");
            this.GET_OrderList();
            this.dialogVisible = false;
            });
          });
        }else if(fhInfo.delivery_dolist.length === 0) {
          API_order.editLogistics(sku_list).then(res => {
            this.$message.success("修改成功");
            this.GET_OrderList();
            this.dialogVisible = false;
          });
        }else {
          API_order.deliveryNew(fhInfo).then(res => {
            this.$message.success("发货成功");
            this.GET_OrderList();
            this.dialogVisible = false;
          });
        }
      });
    },
    openLogisticsDialog(sn) {
      this.sku_list = [];
      this.sn = sn;
      this.logisticsDialog.display();
      API_order.getLogisticsList(this.sn).then(res => {
        this.sku_list = res
      });
    },
    editLogistics() {
      let isDeliveryNo = true;
      this.sku_list.forEach(item=>{
        if(item.delivery_no === "") {
          isDeliveryNo = false
        }
      })
      if (isDeliveryNo === false) {
        this.$message.error("请输入物流单号");
        return
      }
      this.sku_list.order_sn = this.sn
      API_order.editLogistics(this.sku_list).then(res => {
        this.$message.success("保存成功");
        this.GET_OrderList();
      });
    },
    openRemarkDialog(row) {
      this.remarkDialog.display();
      this.remarkForm.sn = row.sn; //订单编号
      this.remarkForm.platform = 0; //平台编号
      this.remarkForm.remark = //订单备注
        row.order_remark === null
          ? row.order_remark
          : row.order_remark.replaceAll("/n", "\n");
      this.remarkForm.member_name = row.member_name;
    },
    editRemark() {
      if (
        this.remarkForm.remark === "" ||
        this.remarkForm.remark === null
      ) {
        return this.$message.error("备注信息不能为空");
      }
      this.remarkForm.remark = this.remarkForm.remark.replace(
        /\n/g,
        "/n"
      );
      API_order.editOrderRemark(this.remarkForm).then(res => {
        this.$message.success("保存成功");
        this.GET_OrderList();
      });
    },
    closeRemarkDialog() {
      this.remarkDialog = false;
      this.remarkTextarea = "";
    },
    /** 获取单位列表 */
    GET_UnitList() {
      this.loading = true;
      const params = this.comParams;
      Object.keys(params).forEach(key => {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      });
      let params1 = {...params};
      params1.cgrade = 1;
      params1.page_size = 9999;
      API_Member.companyGetPageList(params1)
        .then(res => {
          this.loading = false;
          this.tableComData1 = res.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    unitChange(val, type) {
      if (type) {
        let selectName = this.tableComData1.find(
          val => val.id === this.addMemberForm.shop_member_unit_id
        ).name;
        this.$set(
          this.addMemberForm,
          "shop_member_unit_name",
          selectName
        );
      } else {
        let selectName = this.tableComData1.find(
          val => val.id === this.advancedForm.shop_member_unit_id
        ).name;
        this.$set(
          this.advancedForm,
          "shop_member_unit_name",
          selectName
        );
      }

      this.GET_ComList(val, true);
    },
    unitChange1(val, type) {
      this.advancedForm.shop_member_unit_id_two = "";
      if (val) {
        let aaa = {};
        aaa.pid = val;
        aaa.page_size = 9999;
        API_Member.companyGetPageList(aaa)
          .then(res => {
            this.loading = false;
            this.tableComData2 = res.data;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.tableComData2 = [];
      }
    },
    /** 计算高度 */
    countTableHeight() {
      this.tableHeight = document.body.clientHeight - 54 - 35 - 50;
    },

    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_OrderList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_OrderList();
    },

    /** 订单状态改变 */
    changeOrderStatus(data) {
      delete this.params.keywords;
      delete this.params.order_status;
      if (data) {
        this.params = {
          ...this.params,
          order_status: data
        };
        this.params.page_no = 1;
        this.params.page_size = 20;
      }
      Object.keys(this.advancedForm).forEach(
        key => delete this.params[key]
      );
      this.GET_OrderList();
    },

    /** 搜索事件触发 */
    searchEvent(data) {
      this.params = {
        ...this.params,
        keywords: data
      };
      delete this.params.order_status;
      Object.keys(this.advancedForm).forEach(
        key => delete this.params[key]
      );
      this.GET_OrderList();
    },

    /** 高级搜索事件触发 */
    advancedSearchEvent() {
      this.obj = {
        order_sn: "",
        buyer_name: "",
        goods_name: "",
        ship_no: ""
      };
      if (this.keywordsType) {
        this.obj[this.keywordsType] = this.keywords;
      }
      this.params = {
        ...this.params,
        ...this.advancedForm,
        ...this.obj
      };
      delete this.params.start_time;
      delete this.params.end_time;
      delete this.params.deliverStartTime;
      delete this.params.deliverEndTime;
      if (this.advancedForm.order_time_range) {
        this.params.start_time =
          this.advancedForm.order_time_range[0].getTime() / 1000;
        this.params.end_time =
          this.advancedForm.order_time_range[1].getTime() / 1000;
      }
      if (this.advancedForm.deliverTime) {
        this.params.deliverStartTime =
          this.advancedForm.deliverTime[0].getTime() / 1000;
        this.params.deliverEndTime =
          this.advancedForm.deliverTime[1].getTime() / 1000;
      }
      // delete this.params.keywords
      delete this.params.order_time_range;
      delete this.params.deliverTime;
      this.params.page_no = 1;
      this.GET_OrderList();
    },

    /** 发货 */
    deliverGoods(row) {
      if (this.ship_no === "") {
        this.$message.error("请填写物流单号");
        return;
      }

      let _params = {
        /** 物流单号 */
        ship_no: this.ship_no,
        /** 物流公司id */
        logi_id: this.selectValue.split("|")[0],
        /** 物流公司名称 */
        logi_name: this.selectValue.split("|")[1]
      };

      this.$confirm("确认发货?", "提示", {type: "warning"}).then(() => {
        API_order.deliveryGoods(this.sn, _params).then(() => {
          this.$message.success("发货成功");
          setTimeout(() => {
            this.dialogVisible = false;
            this.selectValue = "";
            this.GET_OrderList();
          }, 1000);
          if (res.code === 460) {
            setTimeout(() => {
              this.dialogVisible = false;
              this.selectValue = "";
            }, 1000);
          }
        });
      });
    },

    /** 查看、操作订单 */
    handleOperateOrder(sn) {
      let route = this.$route.path.split("/")[2];
      this.$router.push({path: `/order/${route}/detail/${sn}`});
    },

    excelSuccess({results}) {
      this.isError = false;

      this.templateKey.forEach((item, index) => {
        results = JSON.parse(
          JSON.stringify(results).replace(
            new RegExp(this.templateHedaer[index], "g"),
            item
          )
        );
      });

      for (const item of results) {
        if (!item.product_id) {
          item.errorText = "产品ID必填";
          this.isError = true;
        }

        if (item.order_sn) {
          if (!item.ship_num) {
            item.errorText = "发货数量必填";
            this.isError = true;
          }

          if (!item.ship_num) {
            item.errorText = "发货数量必填";
            this.isError = true;
          }

          if (Number(item.ship_num) > Number(item.num)) {
            list.errorText = "发货数量大于订单数量";
            this.isError = true;
          }

          if (!item.logi_name) {
            list.errorText = "物流公司必填";
            this.isError = true;
          }

          if (!item.delivery_no) {
            list.errorText = "物流单号必填";
            this.isError = true;
          }
        }
      }

      this.importData = results;
    },

    submitFh() {
      this.progress = '0%'
      if (this.isError) {
        this.$message.error("请按校验标识检查数据重新导入");
        return;
      }

      if (!this.importData.length) {
        this.$message.error("请导入至少一条数据");
        return;
      }

      const delivery_dolist = this.importData.reduce((results, item) => {
        if (item.sn) {
          results.push({
            order_sn: item.sn,
            goods_name: item.name,
            goods_sn: item.product_id,
            ship_num: item.num,
            logi_name: item.logi_name,
            delivery_no: item.ship_no
          });
        }
        return results;
      }, []);

      API_order.importOrderRequest(Math.ceil(delivery_dolist.length/100))
      for (let i=1; i<=Math.ceil(delivery_dolist.length/100); i++){
        setTimeout(()=>{  // 为解决后端并发问题添加延时器
        // 拆解数据，分批导入
        let cpData = JSON.parse(JSON.stringify(delivery_dolist))
        let start = (i - 1) * 100
        let end = i * 100 - 1
        let handleData = []
        if (i===Math.ceil(delivery_dolist.length/100)) {
          end = delivery_dolist.length - 1
          handleData = cpData.splice(start,delivery_dolist.length-start)
        }else handleData = cpData.splice(start,100)
        // 开始导入数据
        API_order[this.funcName]({ delivery_dolist: handleData }).then(res => {
          const {no, errorExport} = res;
          if (no !== 0) {
            const {tHeaders, filterVals} = getExportMetadata(2);
            handleDownload(
              errorExport,
              tHeaders,
              filterVals,
              "失败订单列表"
            );
          }
        });
        this.addOrderLoading = true
        this.batchImport = false
        },(i-1)*1500)
      }
      this.pollImportResult()
    },

    pollImportResult(type){
      if (this.pollTime) clearInterval(this.pollTime)
      this.pollTime = setInterval(() => {
        API_order.pollImportResult().then(res=>{
          if (res.surplus === 0) {
            clearInterval(this.pollTime)
            this.addOrderLoading = false
            this.GET_OrderList();
          }else {
            this.progress = Math.ceil(((res.total - res.surplus)/res.total) * 100) + '%'
            this.addOrderLoading = true
          }
        })
      }, 3000)
      if (type==='init') {
        API_order.pollImportResult().then(res=>{
          if (res.surplus === 0) {
            clearInterval(this.pollTime)
            this.addOrderLoading = false
          }else {
            this.progress = Math.ceil(((res.total - res.surplus)/res.total) * 100) + '%'
            this.addOrderLoading = true
          }
        })
      }
    },

        // 导出
    submitImport(type) {
      const {
        orderFunc,
        tHeaders,
        filterVals
      } = getExportMetadata(1);

      let params = JSON.parse(JSON.stringify(this.params))
      if (type === 0) {
        if (this.tempList.length === 0)
          return this.$message.error("请先勾选要导出的订单");
        let snArr = [];
        this.tempList.forEach(item => {
          snArr.push(item.sn);
        });
        params.order_sn_s = snArr.toString();
        this.importLoading = true;
      }else{
        this.importLoading1 = true
      }
      if (params.order_status === 'PICKING'){
        params.logistics_status = 1
        delete params.order_status;
      }
      if (this.advancedForm.order_time_range) {
        params.start_time =
          this.advancedForm.order_time_range[0].getTime() / 1000;
        params.end_time =
          this.advancedForm.order_time_range[1].getTime() / 1000;
      }
      if (this.keywords) {
        params[this.keywordsType] = this.keywords;
      }
      params.page_no = 0;
      params.page_size = 0;
      API_order[orderFunc](params)
        .then(res => {
          res.data = res.data.map(item => {
            const {
              ship_province,
              ship_city,
              ship_county,
              ship_addr,
            } = item;
            item.ship_addr = ship_province + ship_city + ship_county + ship_addr;
            if (item.order_remark){
              const date = this.formatDate(item.remark_update_date*1000)
              item.order_remark = date +"\n"+ item.order_remark
            }
            return item;
          })
          handleDownload(res.data, tHeaders, filterVals, "订单列表");
          this.GET_OrderList();
          this.importLoading = false;
          this.importLoading1 = false;
        })
        .catch(res => {
          this.importLoading = false;
          this.importLoading1 = false;
        });
    },

    formatDate(time) {
      let date = new Date(time);
      let YY = date.getFullYear() + '-';
      let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
      let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      let ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
      return YY + MM + DD +" "+hh + mm + ss;
    },

    GET_OrderList() {
      this.loading = true;

      this.resetSelfGoods();
      let params = JSON.parse(JSON.stringify(this.params))
      if (params.order_status === 'PICKING'){
        params.logistics_status = 1
        delete params.order_status;
      }
      API_order.getSellerOrderList(params).then(res => {
        this.loading = false;
        this.pageData = res;
        this.pageData.data = mergeSkuList(res.data, 3);
      });
    },

    refresh() {
      this.keywords = "";
      this.keywordsType = "order_sn";

      this.params = {
        order_status: "ALL",
        page_no: 1,
        page_size: 20,
        self_goods: 0
      };

      this.advancedForm = {
        shop_member_unit_id: "",
        shop_member_unit_id_two: "",
        order_time_range: null
      };

      this.GET_OrderList();
    }
  }
};

function getExportMetadata(type) {
  let orderFunc, filterVals, tHeaders;
  switch (type) {
    case 1:
      orderFunc = "getSellerOrderList";
      filterVals = [
        "name",
        "supplier_purchase_price",
        "product_id",
        "sku_sn",
        "num",
        "ship_addr",
        "ship_name",
        "ship_mobile",
        "order_sn",
        "order_status_text",
        "logi_name",
        //"ship_status_text",
        "ship_no",
        "member_name",
        "remark",
        "order_remark"
      ];
      tHeaders = [
        "商品名称以及规格",
        "商品单价",
        "产品ID",
        "商品编号",
        "数量",
        "详细地址",
        "姓名",
        "电话",
        "订单号",
        "订单状态",
        "物流公司",
        //"物流状态",
        "物流单号",
        "买家账号",
        '用户备注信息',
        '备注信息',
      ];
      break;
    case 2:
      filterVals = [
        "goods_name",
        "product_id",
        "ship_num",
        // "ship_addr",
        // "ship_name",
        // "ship_mobile",
        "order_sn",
        // "order_status_text",
        "logi_name",
        //"ship_status_text",
        "delivery_no",
        "remark"
      ];
      tHeaders = [
        "商品名称",
        "产品ID",
        "数量",
        // "详细地址",
        // "姓名",
        // "电话",
        "订单号",
        // "订单状态",
        "物流公司",
        //"物流状态",
        "物流单号",
        "错误原因"
      ];
  }

  return {
    orderFunc,
    filterVals,
    tHeaders
  };
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1587px) {
  .union-style-1587 {
    margin-top: 12px;
  }
}

.union-style-1587 {
  width: 385px;
}

.conceal {
  white-space: nowrap; //不换行
  overflow: hidden; //超出则隐藏
}

/deep/.import-order-dialog{
  .el-dialog{
    min-width: 1024px;
    margin-top: 10vh !important;
  }

  .el-dialog__body{
    max-height: 65vh;
    overflow-y: scroll;
  }
}

/deep/.el-loading-spinner{
  .el-icon-loading,.el-loading-text{
    color: white;
    font-size: 16px;
  }
}

.union-ordert-list.remark-box{
  max-width:150px;
  display:inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
